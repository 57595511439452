// Generated by Framer (84ce8e9)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["yCMdPB8cq", "xvCeTZ9_6"];

const variantClassNames = {xvCeTZ9_6: "framer-v-1bx172f", yCMdPB8cq: "framer-v-8j1tzp"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {Menu: "yCMdPB8cq", X: "xvCeTZ9_6"};

const transitions = {default: {damping: 30, delay: 0, mass: 1, stiffness: 400, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tap?: any; color?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "yCMdPB8cq", tap: TBgHLr7MW, color: mP9Tz30oK = "var(--token-e0ce79e8-f023-49ad-9edf-bfcca13d71cf, rgb(7, 15, 75)) /* {\"name\":\"Background - Dark\"} */", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "yCMdPB8cq", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTaph77ube = activeVariantCallback(async (...args) => {
if (TBgHLr7MW) {
const res = await TBgHLr7MW(...args);
if (res === false) return false;
}
setVariant("xvCeTZ9_6")
})

const onTap1dxr0jz = activeVariantCallback(async (...args) => {
if (TBgHLr7MW) {
const res = await TBgHLr7MW(...args);
if (res === false) return false;
}
setVariant("yCMdPB8cq")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-WcIUQ", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-8j1tzp", className)} data-framer-name={"Menu"} data-highlight layoutDependency={layoutDependency} layoutId={"yCMdPB8cq"} onTap={onTaph77ube} ref={ref} style={{...style}} {...addPropertyOverrides({xvCeTZ9_6: {"data-framer-name": "X", onTap: onTap1dxr0jz}}, baseVariant, gestureVariant)}><motion.div className={"framer-8cf5zq"} data-framer-name={"Bottom Line"} layoutDependency={layoutDependency} layoutId={"yIn98Ldxw"} style={{backgroundColor: mP9Tz30oK, rotate: 0}} variants={{xvCeTZ9_6: {rotate: -45}}}/><motion.div className={"framer-h2tgfw"} data-framer-name={"Middle Line"} layoutDependency={layoutDependency} layoutId={"F8TpTpUke"} style={{backgroundColor: mP9Tz30oK, opacity: 1}} variants={{xvCeTZ9_6: {opacity: 0}}}/><motion.div className={"framer-4wzfvp"} data-framer-name={"Top Line"} layoutDependency={layoutDependency} layoutId={"HNCxjDNeN"} style={{backgroundColor: mP9Tz30oK, rotate: 0}} variants={{xvCeTZ9_6: {rotate: 45}}}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-WcIUQ [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-WcIUQ .framer-1wlmna0 { display: block; }", ".framer-WcIUQ .framer-8j1tzp { cursor: pointer; height: 32px; overflow: hidden; position: relative; width: 32px; }", ".framer-WcIUQ .framer-8cf5zq { bottom: 7px; flex: none; height: 2px; left: calc(50.00000000000002% - 24px / 2); overflow: hidden; position: absolute; width: 24px; }", ".framer-WcIUQ .framer-h2tgfw { flex: none; height: 2px; left: calc(50.00000000000002% - 24px / 2); overflow: hidden; position: absolute; top: calc(50.00000000000002% - 2px / 2); width: 24px; }", ".framer-WcIUQ .framer-4wzfvp { flex: none; height: 2px; left: calc(50.00000000000002% - 24px / 2); overflow: hidden; position: absolute; top: 7px; width: 24px; }", ".framer-WcIUQ.framer-v-1bx172f .framer-8j1tzp { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 32px); }", ".framer-WcIUQ.framer-v-1bx172f .framer-8cf5zq { bottom: unset; top: calc(50.00000000000002% - 2px / 2); }", ".framer-WcIUQ.framer-v-1bx172f .framer-h2tgfw { left: calc(50.00000000000002% - 2px / 2); width: 2px; }", ".framer-WcIUQ.framer-v-1bx172f .framer-4wzfvp { left: unset; right: 4px; top: calc(50.00000000000002% - 2px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"xvCeTZ9_6":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"TBgHLr7MW":"tap","mP9Tz30oK":"color"}
 */
const Framerzf49p39_m: React.ComponentType<Props> = withCSS(Component, css, "framer-WcIUQ") as typeof Component;
export default Framerzf49p39_m;

Framerzf49p39_m.displayName = "Menu Icon";

Framerzf49p39_m.defaultProps = {height: 32, width: 32};

addPropertyControls(Framerzf49p39_m, {variant: {options: ["yCMdPB8cq", "xvCeTZ9_6"], optionTitles: ["Menu", "X"], title: "Variant", type: ControlType.Enum}, TBgHLr7MW: {title: "Tap", type: ControlType.EventHandler}, mP9Tz30oK: {defaultValue: "var(--token-e0ce79e8-f023-49ad-9edf-bfcca13d71cf, rgb(7, 15, 75)) /* {\"name\":\"Background - Dark\"} */", title: "Color", type: ControlType.Color}} as any)

addFonts(Framerzf49p39_m, [])